/**
 * Renders non-reversal line items that are not listed in the
 * `LINE_ITEMS` array in util/types.js
 *
 * The line items are rendered so that the line item code is formatted to human
 * readable form and the line total is printed as price.
 *
 * If you require another kind of presentation for your line items, add them to
 * the `LINE_ITEMS` array in util/types.js and create a specific line item
 * component for them that can be used in the `BookingBreakdown` component.
 */
import React from 'react';
import { intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEMS, propTypes } from '../../util/types';
import { humanizeLineItemCode } from '../../util/data';

import css from './BookingBreakdown.module.css';

const InsuranceItemBasePrice = props => {
  const LINE_INSURANCE = 'line-item/tarifa-de-seguro';
  const LINE_BOND = 'line-item/bond';
  const LINE_BOND_LABEL = 'Tarifa de fianza';

  const { transaction, isProvider, intl } = props;

  const allItems = transaction.attributes.lineItems.filter(
    item => LINE_ITEMS.indexOf(item.code) === -1 && !item.reversal
  );
  const items = isProvider
    ? allItems.filter(item => item.includeFor.includes('provider'))
    : allItems.filter(item => item.includeFor.includes('customer'));

  if (items.length === 0) {
    return null;
  }

  return (
    <React.Fragment>
      {items.map((item, i) => {
        const quantity = Number(item.quantity) || 1;
        const unitPrice = formatMoney(intl, item.unitPrice);
        const formattedTotal = formatMoney(intl, item.lineTotal);

        // Always show the multiplier postfix for insurance:
        // " * 1 día" for one day and " * n días" for more than one day.
        const postfix = quantity > 1 ? ` * ${quantity} días` : ` * ${quantity} día`;
        const useItemBondLabel = item.code !== LINE_BOND;
        const label = useItemBondLabel ? humanizeLineItemCode(item.code) : LINE_BOND_LABEL;

        return (
          <div key={`${i}-${item.code}`} className={css.lineItem}>
            <span className={css.itemLabel}>
              {label}
              {item.code === LINE_INSURANCE ? ` ${unitPrice} ${postfix}` : null}
            </span>
            <span className={css.itemValue}>{formattedTotal}</span>
          </div>
        );
      })}
    </React.Fragment>
  );
};

InsuranceItemBasePrice.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default InsuranceItemBasePrice;
